import React from 'react'
import '../App.css';
import Bounce from 'react-reveal/Bounce';



function About() {
  return (
    <div id='about'>
    <div className='container md:text-center mx-auto md:max-w-2xl'>
      <Bounce>
      <div className='mt-14 md:mt-20'>
        <h2 className='header text-center p-6 md:pt-12'>About me</h2>
      </div>
      <div className='about-text text-lg p-3 text-center md:p-4 md:text-xl'>
        <p>
        Innovative software engineer with experience in developing
        web applications by utilizing a variety of programming languages and
        frameworks. Skilled in collaborating with cross-functional teams to
        deliver high-quality software solutions that meet both client and user
        needs.
        </p>
      </div>
      </Bounce>
    </div>
    </div>
  )
}

export default About;
