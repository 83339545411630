import React from 'react'
import '../App.css'
import { useState } from 'react';
import { useEffect } from 'react';
import {Link} from 'react-scroll'
import Headroom from 'react-headroom';


function Navbar() {

  const [theme, setTheme] = useState('dark');

  useEffect (() => {
    if (theme === 'dark'){
      document.documentElement.classList.add('dark');
    }
    else{
      document.documentElement.classList.remove('dark');
    }
  },[theme])

  const handleThemeChange = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  
  return (
      // Navbar container
      <Headroom>
    <div className='container mx-auto pt-10 pb-10 bg-gray-700 text-white dark:bg-white opacity-95 dark:opacity-95'>
      <nav className='nav-texts hidden space-x-24 text-lg justify-center md:flex md:flex-row'>
          <Link to='home' spy={true} smooth={true} offset={-150} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Home</Link>
          <Link to='about' spy={true} smooth={true} offset={-90} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>About</Link>
          <Link to='skills' spy={true} smooth={true} offset={50} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Skills</Link>
          <Link to='projects' spy={true} smooth={true} offset={50} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Projects</Link>
          <Link to='contact' spy={true} smooth={true} offset={50} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Contact</Link>
          <button onClick={handleThemeChange}>
          { 
          theme === 'light'? <img width="30" height="30" src="https://img.icons8.com/ios/50/moon-symbol.png" alt="moon-symbol"/> : 
          <img width="40" height="40" src="https://img.icons8.com/cotton/64/light-on.png" alt="light-on"/>
          }
          </button>
      </nav>

      <nav className='nav-texts md:hidden text-sm space-x-5 justify-center flex flex-row mx-auto max-w-fit'>
          <Link to='home' spy={true} smooth={true} offset={-150} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Home</Link>
          <Link to='about' spy={true} smooth={true} offset={-90} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>About</Link>
          <Link to='skills' spy={true} smooth={true} offset={50} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Skills</Link>
          <Link to='projects' spy={true} smooth={true} offset={50} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Projects</Link>
          <Link to='contact' spy={true} smooth={true} offset={50} duration={500} className='hover:text-gray-400 hover:cursor-pointer dark:text-black dark:hover:text-sky-900 dark:hover:underline'>Contact</Link>
          <button onClick={handleThemeChange}>
          { 
          theme === 'light'? <img width="30" height="30" src="https://img.icons8.com/ios/50/moon-symbol.png" alt="moon-symbol"/> : 
          <img width="40" height="40" src="https://img.icons8.com/cotton/64/light-on.png" alt="light-on"/>
          }
          </button>
      </nav>

    </div>
    </Headroom>
  )
}

export default Navbar
