import React from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import styled from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import '../App.css'
import { useRef } from 'react';



function Contact() {

  const form = useRef();
  const notify = () => toast('Sent!')


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kgyrxhl', 'template_inkui82', form.current, 'dF_8QpOSyn4G4bFSv')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='container flex flex-col mx-auto items-center' id='contact'>
      <div className='header text-center p-6 pb-6 mt-10 md:mt-24 md:p-12 md:pb-14'>
            <span>Let's connect!</span>
        </div>

            
            <Styling>
    <form ref={form} onSubmit={sendEmail}>
      <label className='dark:text-white'>Name</label>
      <input type="text" name="user_name" required />
      <label className='dark:text-white'>Your email</label>
      <input type="email" name="user_email" required/>
      <label className='dark:text-white'>Message</label>
      <textarea name="message" />
      <input type="submit" onClick={notify} value="Hit me up!" />
      <ToastContainer
      position="top-right"
      autoClose={700}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark" />
    </form>
    </Styling>

    </div>
  )
  }


  const Styling = styled.div`
  width: 100%; /* Set the width of the container to 100% */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */

  form {
    display: flex;
    flex-direction: column;
    // width: 100%;
    font-size: 1rem;
    color: #000000;
    width: 30rem;
    padding: 1rem;


    input {
      width: 100%;
      height: 2rem;
      padding: 1rem;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(70, 130, 180);

      &:focus {
        border: 2px solid rgb(70, 130, 180));
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 10rem;
      min-height: 10rem;
      padding: 1rem;
      outline: none;
      border-radius: 1rem;
      border: 1px solid rgb(70, 130, 180);
      color: #000000;

      &:focus {
        border: 1px solid rgba(70, 130, 180);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      font-family: gother;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 0.5em;
      text-decoration: none;
      color: #333333;
      font-size: 18px;
      border-radius: 0px;
      width: 200px;
      height: 40px;
      font-weight: bold;
      border: 2px solid #333333;
      transition: 0.3s;
      box-shadow: 5px 5px 0px 0px rgba(51, 51, 51, 1);
      background-color: #ffffff;
      margin-top: 2rem;
    }
    input[type="submit"]:hover {
      box-shadow: 0 0 #333;
      color: #fff;
      background-color: #000000;
      cursor : pointer;
  }
  }
`;
export default Contact;
