import './App.css';
import About from './Components/About';
import Navbar from './Components/Navbar';
import Profile from './Components/Profile';
import Projects from './Components/Projects';
import Skills from './Components/Skills';
import Contact from './Components/Contact';
import Socials from './Components/Socials';
import Copyright from './Components/Copyright';


function App() {

  return (
    <div className='dark:bg-gray-800 dark:text-white max-w-[1640px]'>
      <Navbar/>
      <Profile />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Socials />
      <Copyright />
    </div>
  );
}

export default App;
