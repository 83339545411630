import React from 'react'
import '../App.css';


function Socials() {
  return (
    <div>
      <div className='container mt-12 md:mt-20'>
        <div className='find-text text-center md:mb-8'>
            <span>You can find me on</span>
        </div>
        <div className='flex flex-row justify-center md:gap-10 hover:cursor-pointer gap-10 md:p-1 p-5 '>
                <a href='https://twitter.com/clegane89' target='_blank' rel="noreferrer"><img className='x-logo hover:scale-90 duration-300' src='https://tinyurl.com/2x3n47ba' alt='x-logo'/></a>
 
                <a href='https://www.instagram.com/ghost30390/#' target='_blank' rel="noreferrer"><img className='ig-logo hover:scale-90 duration-300' src='https://tinyurl.com/26jppmop' alt='ig-logo'/></a>
 
                <a href='https://www.linkedin.com/in/james-kamau1258/' target='_blank' rel="noreferrer"><img className='linkedin-logo hover:scale-90 duration-300' src='https://tinyurl.com/yot7paq8' alt='linked-logo'/></a>

        </div>
      </div>
    </div>
  )
}

export default Socials
