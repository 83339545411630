import React from 'react'
import '../App.css'

function Skills() {
  return (
    <div id='skills'>
        <div className='header text-center p-6 pb-10 mt-10 md:mt-14 md:p-12 md:pb-16'>
            <h2>Skills</h2>
        </div>
      <div className='container max-w-max mx-auto grid grid-cols-2 md:grid md:grid-cols-4 gap-10 md:pl-28 md:pr-28' >
        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='logo mt-2' src='https://tinyurl.com/yv5nze6w' alt='html-logo'/>
            <div className='skill-label mt-4 p-3'>
                <span>HTML</span>
            </div>
        </div>

        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='logo mt-2' src='https://tinyurl.com/yw7mw6xu' alt='css-logo'/>
            <div className='skill-label p-3 mt-4'>
                <span>CSS</span>
            </div>
        </div>

        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='logo mt-2' src='https://tinyurl.com/ykszhdej' alt='js-logo'/>
            <div className='skill-label p-3 mt-4'>
                <span>JavaScript</span>
            </div>
        </div>

        <div className='skills items-center flex flex-col border border-slate-800 shadow-2xl'>
            <img className='logo mt-2' src='https://tinyurl.com/ylkzfl6s' alt='react-logo'/>
            <div className='skill-label p-3 mt-4'>
                <span>React js</span>
            </div>
        </div>

        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='logo mt-2' src='https://tinyurl.com/2dqupq4z' alt='python-logo'/>
            <div className='skill-label p-3 mt-4'>
                <span>Python</span>
            </div>
        </div>

        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='logo mt-2' src='https://tinyurl.com/yreqf6ub' alt='flask-logo'/>
            <div className='skill-label p-3 mt-4'>
                <span>Flask</span>
            </div>
        </div>

        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='mysql-logo mt-2' src='https://tinyurl.com/yvmdocbg' alt='mysql-logo'/>
            <div className='skill-label p-3 mt-4'>
                <span>MySQL</span>
            </div>
        </div>

        <div className='skills flex flex-col items-center border border-slate-800 shadow-2xl'>
            <img className='aws-logo' src='https://tinyurl.com/ytpxhh8y' alt='aws-logo'/>
            <div className='skill-label'>
                <span>aws cloud</span>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
