import React from 'react';
import '../App.css';
import closeImage from '../Images/close.jpg';
import {Typewriter, Cursor} from 'react-simple-typewriter'
import resume from '../Files/resume.pdf';


function Profile() {

  const openPDFInNewWindow = () => {
    // Replace 'your_resume.pdf' with the actual path to your PDF file
    const resumeFilePath = resume;
    window.open(resumeFilePath, '_blank');
  };


  return (
    <div id='home'>
    {/* Profile Container */}
    <div className='container flex flex-col-reverse mx-auto md:flex md:flex-row justify-center items-center mt-12 md:mt-20'>
      <div className='text-center w-2/4 md:w-1/4'>
        <p className='profile-text mt-16 md:mt-12 md:mb-6'>
          <span>Hi, I am James Kamau</span>
          <br />
          <span style={{color: 'red', font: 'bold'}}>
            <Typewriter
                words = {['a Front-End Engineer', 'and', 'a Back-End Engineer', 'welcome have a look!']}
                loop = {10}
                typeSpeed =  {80}
                deleteSpeed = {60}
                delaySpeed = {1000}

                />
          </span>
          <span style={{color: 'green'}}>
            <Cursor cursorStyle = '<'/>
          </span>
        </p>
        {/* button */}
        <button className='btn border-black border-2 shadow-xl mt-8 pl-2 pr-2 md:pl-2 md:pr-2 md:pt-1 md:pb-1'onClick={openPDFInNewWindow}>My resume</button>
      </div>
        {/* Profile image */}
      <div>
        <img className='profile-img md:rounded-full dark:rounded-t-full object-cover' src={closeImage} alt='face' />
      </div>
    </div>
    </div>
  );
}

export default Profile;


