import React from 'react'
import '../App.css'
import { Card } from 'flowbite-react';



function Projects() {
  return (
    <div className='max-w-[1600px]' id='projects'>
      <div className='header text-center p-6 pb-10 mt-10 md:mt-14 md:p-12 md:pb-16'>
        <h1>Projects</h1>
      </div>

      <div className='container mx-auto flex justify-center'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-3'>
        {/* project 1 */}

        <Card
      className="max-w-sm"
      imgAlt="Tic tac toe"
      imgSrc="https://images.pexels.com/photos/220057/pexels-photo-220057.jpeg?auto=compress&cs=tinysrgb&w=400"
    >
      <h5 className="project-header tracking-tight text-gray-900 dark:text-white">
        Tic Tac Toe
      </h5>
      <p className="project-text text-gray-700 dark:text-white">
      Experience a classic gaming experience with my React-powered 
      Tic-Tac-Toe game, featuring seamless interactivity, responsive design, and nostalgia. 
      Challenge friends and let the Xs and Os define your strategy.
      </p>
      <div className='flex flex-row justify-between'>
    <a href="https://tictactoe-ten-chi.vercel.app/" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Live link</a>
      <a href="https://github.com/James-Kamau3/tictactoe" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Github</a>
      </div>

    </Card>
        

                {/* project 2 */}

        <Card
      className="max-w-sm"
      imgAlt="Law firm"
      imgSrc="https://cdn.vectorstock.com/i/preview-1x/43/84/scales-justice-symbol-vector-26554384.jpg"
    >
      <h5 className="project-header tracking-tight text-gray-900 dark:text-white">
        Law Firm
      </h5>
      <p className="project-text text-gray-700 dark:text-white">
      Innovative law firm web app revolutionizes case management, 
      streamlines collaboration, optimizes efficiency, and empowers 
      professionals to work from anywhere, enhancing case management and firm marketing.
      </p>
      <div className='flex flex-row justify-between'>
    <a href="https://wambui-firm-project.vercel.app/" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Live link</a>
      <a href="https://github.com/James-Kamau3/wambui-firm-project" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Github</a>
      </div>

    </Card>

                {/* project 3 */}


        <Card
      className="max-w-sm"
      imgAlt="Manage landing page"
      imgSrc="https://tinyurl.com/ysrm5qlx"
    >
      <h5 className="project-header tracking-tight text-gray-900 dark:text-white">
        Manage Landing Page
      </h5>
      <p className="project-text text-gray-700 dark:text-white">
      Dynamic landing page management enhances productivity by 
      allowing users to prioritize tasks, track progress, and collaborate 
      easily, with customizable features for a user-friendly experience.
      </p>
      <div className='flex flex-row justify-between pt-'>
      <a href="https://manage-landing-page-ji4y.vercel.app/" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Live link</a>
      <a href="https://github.com/James-Kamau3/manage-landing-page" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Github</a>
      </div>
    </Card>



            {/* Project 4 */}
        <Card
      className="max-w-sm"
      imgAlt="Best Eats"
      imgSrc="http://tinyurl.com/ykc3mwcc"
    >
      <h5 className="project-header tracking-tight text-gray-900 dark:text-white">
        Best Eats
      </h5>
      <p className="project-text text-gray-700 dark:text-white">
      Best Eats offers a user-friendly interface for seamless 
      navigation, diverse menus, and exclusive offers, making ordering 
      food online a breeze and enhancing your dining experience.
      </p>
      <div className='flex flex-row justify-between'>
    <a href="https://best-eats-sooty.vercel.app/" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Live link</a>
      <a href="https://github.com/James-Kamau3/best-eats" className='text-blue-600 hover:text-red-500 underline' target='_blank' rel="noreferrer">Github</a>
      </div>

    </Card>
      </div>
    </div>
    </div>
  )
}

export default Projects;
