import React from 'react'

function Copyright() {
  return (
    <div>
        <div className='container flex flex-row  justify-center mx-auto md:mt-10'>
            <span>&copy; 2023 all rights reserved</span>

        </div>
      
    </div>
  )
}

export default Copyright
